import {
  MailIcon,
  PhoneIcon,
  UserIcon,
  CakeIcon,
  IdentificationIcon,
  LocationMarkerIcon,
  CheckCircleIcon,
  ClockIcon,
} from '@heroicons/react/outline';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AssignConsultation from './AssignConsultation';
import { MedirisDropdownButton } from './MedirisDropdownButton';
import TransferConsultationToggleButton from './TransferConsultation/ToggleButton';
import { CountryFlag } from '../../../common/CountryFlag/CountryFlag';
import { UserContext } from '../../../common/authentication/UserContext';
import PermissionsContext from '../../../common/context/PermissionContext';
import { SuccessNotification } from '../../../common/notification/components';
import { copyToClipboard } from '../../../common/utils/clipboard';
import { calculateAge } from '../../../common/utils/date';
import { canTransferConsultation } from '../../../common/utils/permissions';
import { Consultation, User } from '../../graphql/types';

interface Props {
  doctor: User;
  patient: User;
  onHistoryButtonClick: () => void;
  consultation: Consultation;
  consultationUpdateCallback: (updatedConsultation: Consultation) => void;
}

export function PatientHeader(props: Props) {
  const { patient, doctor, onHistoryButtonClick, consultation, consultationUpdateCallback } = props;
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [copyNotificationOpen, setCopyNotificationOpen] = useState<boolean>(false);
  const { permissions } = useContext(PermissionsContext);

  function onCopyClick(
    event: React.MouseEvent<HTMLElement>,
    value: string | undefined | null,
    sanitize: boolean = false
  ): void {
    event.preventDefault();
    setCopyNotificationOpen(false);
    if (value) {
      copyToClipboard(sanitize ? value.replace(/\D/g, '') : value);
      setCopyNotificationOpen(true);
    }
  }

  function highlightLabel(label: string) {
    return (
      <span className="inline-flex items-center px-1 py-0.5 rounded bg-pink-100 text-pink-800">
        <CheckCircleIcon className="mr-1 h-5 w-5 text-pink-800" />
        {label}
      </span>
    );
  }

  const consultationOwner = (): boolean => {
    if (!user) {
      return false;
    }

    return user.id.toString() === consultation.doctor_id.toString();
  };

  return (
    <div className="bg-white z-10 flex-1 h-full">
      <SuccessNotification
        title={t('common:copied_to_clipboard')}
        isOpen={copyNotificationOpen}
        onClose={() => setCopyNotificationOpen(false)}
      />
      <div className="px-4 sm:px-6">
        <div className="py-4 md:flex md:justify-between md:items-center">
          <div className="flex-1 min-w-0 mb-2 md:mb-0">
            <div className="flex items-center">
              <div>
                <Link
                  className="flex items-center"
                  to={`/doctor/patients/${patient.id}`}
                  onClick={(event) => {
                    if (!consultationOwner()) {
                      event.preventDefault();
                    }
                  }}
                >
                  <div className="ml-3 flex items-center">
                    {patient.patient_settings?.country && <CountryFlag country={patient.patient_settings.country} />}
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-8 sm:truncate">
                      {patient.first_name} {patient.last_name}
                    </h1>
                  </div>
                </Link>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-0 sm:flex-row sm:flex-wrap sm:items-center space-y-2">
                  {patient.email && (
                    <>
                      <dt className="sr-only">{t('common:email_address')}</dt>
                      <Link to="/" onClick={(event) => onCopyClick(event, patient.email)}>
                        <dd className="mt-1 flex items-center text-sm leading-5 text-gray-500 hover:text-gray-400 font-medium sm:mr-4 sm:mt-0 transition ease-in-out duration-150">
                          <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                          {patient.email}
                        </dd>
                      </Link>
                    </>
                  )}
                  {patient.patient_settings?.phone_number && (
                    <>
                      <dt className="sr-only">{t('common:phone_number')}</dt>
                      <Link to="/" onClick={(event) => onCopyClick(event, patient.patient_settings?.phone_number)}>
                        <dd className="mt-1 flex items-center text-sm leading-5 text-gray-500 hover:text-gray-400 font-medium sm:mr-4 sm:mt-0 transition ease-in-out duration-150">
                          <PhoneIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                          {patient.patient_settings.phone_number}
                        </dd>
                      </Link>
                    </>
                  )}
                  {patient.patient_settings?.gender && (
                    <>
                      <dt className="sr-only">{t('common:gender')}</dt>
                      <Link
                        to="/"
                        onClick={(event) => onCopyClick(event, t(`common:${patient.patient_settings?.gender}`))}
                      >
                        <dd className="mt-1 flex items-center text-sm leading-5 text-gray-500 hover:text-gray-400 font-medium sm:mr-4 sm:mt-0 transition ease-in-out duration-150">
                          <UserIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                          {t(`common:${patient.patient_settings.gender}`)}
                        </dd>
                      </Link>
                    </>
                  )}
                  {patient.patient_settings?.date_of_birth && (
                    <>
                      <dt className="sr-only">{t('common:age')}</dt>
                      <Link
                        to="/"
                        onClick={(event) =>
                          onCopyClick(event, calculateAge(patient.patient_settings?.date_of_birth).toString())
                        }
                      >
                        <dd className="mt-1 flex items-center text-sm leading-5 text-gray-500 hover:text-gray-400 font-medium sm:mr-4 sm:mt-0 transition ease-in-out duration-150">
                          <CakeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                          {calculateAge(patient.patient_settings.date_of_birth)}
                        </dd>
                      </Link>
                    </>
                  )}
                  {patient.patient_settings?.postal_code && (
                    <>
                      <dt className="sr-only">{t('common:postal_code')}</dt>
                      <Link to="/" onClick={(event) => onCopyClick(event, patient.patient_settings?.postal_code)}>
                        <dd className="mt-1 flex items-center text-sm leading-5 text-gray-500 hover:text-gray-400 font-medium sm:mr-4 sm:mt-0 transition ease-in-out duration-150">
                          <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                          {patient.patient_settings.postal_code}
                        </dd>
                      </Link>
                    </>
                  )}
                  {patient.patient_settings?.identification_number && (
                    <>
                      <dt className="sr-only">{t('common:identification_number')}</dt>
                      <Link
                        to="/"
                        onClick={(event) => onCopyClick(event, patient.patient_settings?.identification_number, true)}
                      >
                        <dd className="mt-1 flex items-center text-sm leading-5 text-gray-500 hover:text-gray-400 font-medium sm:mr-4 sm:mt-0 transition ease-in-out duration-150">
                          <IdentificationIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                          {patient.patient_settings.identification_number}
                        </dd>
                      </Link>
                    </>
                  )}
                  {!!patient.patient_settings?.is_pregnant && (
                    <>
                      <dt className="sr-only">{t('common:is_pregnant')}</dt>
                      <dd className="mt-1 flex items-center text-sm leading-5 font-medium sm:mr-4 sm:mt-0">
                        {highlightLabel(t('common:pregnant_or_breast_feeding'))}
                      </dd>
                    </>
                  )}
                  {!!patient.patient_settings?.birth_control && (
                    <>
                      <dt className="sr-only">{t('common:is_taking_birth_control')}</dt>
                      <dd className="mt-1 flex items-center text-sm leading-5 font-medium sm:mr-4 sm:mt-0">
                        {highlightLabel(t('common:is_taking_birth_control'))}
                      </dd>
                    </>
                  )}
                </dl>
              </div>
            </div>
          </div>
          <div className="flex space-x-2 self-start">
            <div className="flex flex-col space-y-2">
              <div className="flex md:ml-auto">
                {permissions && user && canTransferConsultation(permissions, user, consultation.doctor_id) && (
                  <div className="mr-3 mt-1 self-center">
                    <TransferConsultationToggleButton
                      consultationDoctorId={consultation.doctor_id}
                      consultationUpdateCallback={consultationUpdateCallback}
                    />
                  </div>
                )}
                {consultation.user_has_write_access && (
                  <AssignConsultation
                    consultationUpdateCallback={consultationUpdateCallback}
                    doctorId={consultation.doctor_id}
                    userSettings={consultation.user_settings}
                  />
                )}
                <button className="relative ml-2" title={t('common:history')} onClick={onHistoryButtonClick}>
                  <ClockIcon className="h-5 w-5 text-gray-400" />
                  {consultation.patient.consultation_count > 1 && (
                    <div className="absolute top-0 left-3 w-4 h-4 flex items-center justify-center bg-teal-500 text-white text-xxs rounded-full">
                      {consultation.patient.consultation_count - 1}
                    </div>
                  )}
                </button>
              </div>
              {consultationOwner() && !!doctor.doctor_settings?.mediris_integration && (
                <MedirisDropdownButton patient={patient} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
