import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SkincareModalProps } from './SkincareModal.types';
import ModalWrapper from '../../../../common/ModalWrapper';
import TinyRichTextEditor from '../../../../common/TinyRichTextEditor';
import { EMPTY_CONTENT } from '../../../../common/constants/message';
import { SearchSkincareProductsProvider } from '../../../../common/context/SearchSkincareProductsContext';
import { useSkincare } from '../../../../common/context/SkincareContext';
import { Button } from '../../../../common/form/components';
import {
  GENERATE_CONSULTATION_SKINCARE,
  SEND_CONSULTATION_SKINCARE,
} from '../../../graphql/models/ConsultationSkincare';
import { SkincareProduct } from '../../../graphql/types';
import SearchSkincareProducts from '../SearchSkincareProducts';

export const SkincareModal = (props: SkincareModalProps) => {
  const { consultationId, createMessageCallback, visibility, setVisibility } = props;
  const { t } = useTranslation();
  const { state, dispatch } = useSkincare();
  const [generateConsultationSkincare, { error: generateConsultationSkincareError }] =
    useMutation(GENERATE_CONSULTATION_SKINCARE);
  const [sendConsultationSkincare] = useMutation(SEND_CONSULTATION_SKINCARE);

  useEffect(() => {
    if (visibility) {
      createSkincarePlanHandler();
    }
  }, [visibility]);

  useEffect(() => {
    if (generateConsultationSkincareError) {
      dispatch({ type: 'SET_FIELDS', payload: { loading: false, generateError: 'generateConsultationSkincareError' } });
    }
  }, [generateConsultationSkincareError]);

  const createSkincarePlanHandler = async () => {
    try {
      const response = await generateConsultationSkincare({ variables: { consultationId } });

      if (response.data.generateConsultationSkincare) {
        const { intro, morning, evening, additional_tips, outro, products } =
          response.data.generateConsultationSkincare;

        dispatch({
          type: 'SET_FIELDS',
          payload: {
            loading: false,
            intro,
            morning,
            evening,
            additionalTips: additional_tips,
            outro,
            products,
          },
        });
      } else if (response.errors) {
        errorHandler(response.errors);
      }
    } catch (exception) {
      errorHandler(exception);
    }
  };

  const sendSkincarePlanHandler = async () => {
    try {
      const productIds = state.products.map((product) => product.id);
      const response = await sendConsultationSkincare({
        variables: {
          input: {
            consultation_id: consultationId,
            intro: state.intro !== EMPTY_CONTENT ? state.intro : null,
            morning: state.morning !== EMPTY_CONTENT ? state.morning : null,
            evening: state.evening !== EMPTY_CONTENT ? state.evening : null,
            additional_tips: state.additionalTips !== EMPTY_CONTENT ? state.additionalTips : null,
            outro: state.outro !== EMPTY_CONTENT ? state.outro : null,
            product_ids: productIds,
          },
        },
      });

      if (response.data.sendConsultationSkincare) {
        createMessageCallback(response.data.sendConsultationSkincare);
        onCloseModalHandler(false);
      } else if (response.errors) {
        errorHandler(response.errors);
      }
    } catch (exception) {
      errorHandler(exception);
    }
  };

  const errorHandler = (errors: any) => {
    // TODO: error handling
    console.log(errors);
  };

  const onCloseModalHandler = (modalVisible: boolean): void => {
    if (state.loading) {
      return;
    }

    setVisibility(modalVisible);
    setTimeout(() => {
      dispatch({ type: 'CLEAR' });
    }, 200); // Transition time
  };

  const onChangeHandler = (name: string, value: string) => {
    dispatch({ type: 'SET_FIELDS', payload: { [name]: value } });
  };

  const selectedProductsUpdateCallbackHandler = (products: SkincareProduct[]) => {
    dispatch({ type: 'SET_FIELDS', payload: { products } });
  };

  return (
    <ModalWrapper
      title={t('doctor:consultation:skincare:modal:title')}
      setVisibility={onCloseModalHandler}
      visible={visibility}
      size="large"
    >
      {state.loading && <p>loading</p>}
      {state.generateError && <p>{t('doctor:consultation:skincare:modal:generate_error')}</p>}
      {!state.loading && !generateConsultationSkincareError && (
        <>
          <div className="space-y-2">
            <TinyRichTextEditor
              label={t('doctor:consultation:skincare:modal:form:intro')}
              name="intro"
              onChangeCallback={onChangeHandler}
              value={state.intro}
            />
            <TinyRichTextEditor
              label={t('doctor:consultation:skincare:modal:form:morning')}
              name="morning"
              onChangeCallback={onChangeHandler}
              value={state.morning}
            />
            <TinyRichTextEditor
              label={t('doctor:consultation:skincare:modal:form:evening')}
              name="evening"
              onChangeCallback={onChangeHandler}
              value={state.evening}
            />
            <TinyRichTextEditor
              label={t('doctor:consultation:skincare:modal:form:additional_tips')}
              name="additionalTips"
              onChangeCallback={onChangeHandler}
              value={state.additionalTips}
            />
            <TinyRichTextEditor
              label={t('doctor:consultation:skincare:modal:form:outro')}
              name="outro"
              onChangeCallback={onChangeHandler}
              value={state.outro}
            />
            <SearchSkincareProductsProvider>
              <SearchSkincareProducts
                selectedProductsUpdateCallback={selectedProductsUpdateCallbackHandler}
                defaultSelectedProducts={state.products}
              />
            </SearchSkincareProductsProvider>
          </div>
          <div className="flex mt-2">
            <Button
              label={t('doctor:consultation:diagnosis_treatment:modal:form:send_btn')}
              onClick={sendSkincarePlanHandler}
            />
            <Button
              className="ml-4"
              label={t('doctor:consultation:diagnosis_treatment:modal:form:cancel_btn')}
              variant="secondary"
              onClick={() => {
                onCloseModalHandler(false);
              }}
            />
          </div>
        </>
      )}
    </ModalWrapper>
  );
};
