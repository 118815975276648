import { gql } from '@apollo/client';

export const GENERATE_CONSULTATION_SKINCARE = gql`
  mutation generateConsultationSkincare($consultationId: ID!) {
    generateConsultationSkincare(consultation_id: $consultationId) {
      intro
      morning
      evening
      additional_tips
      outro
      products {
        id
        product_id
        title
      }
    }
  }
`;

export const SEND_CONSULTATION_SKINCARE = gql`
  mutation generateConsultationSkincare($input: SendConsultationSkincareInput!) {
    sendConsultationSkincare(input: $input) {
      id
      body {
        ... on TextMessage {
          id
          content
        }
      }
      created_at
      deleted_at
      body_type
      sender {
        id
        last_name
        first_name
        avatar
      }
    }
  }
`;
