import { AppPermissionName } from '../../doctor/graphql/types';
import { User } from '../authentication/types';

export const canTransferConsultation = (permissions: AppPermissionName[], user: User, doctorId: string): boolean => {
  const hasTransferConsultationPermission = permissions.includes(AppPermissionName.TransferConsultation);

  if (
    user.is_doctor &&
    !user.is_medical_care &&
    !canWriteOthersConsultations(permissions) &&
    user.id.toString() !== doctorId.toString()
  ) {
    return false;
  }

  return hasTransferConsultationPermission;
};

export const canWriteOthersConsultations = (permissions: AppPermissionName[]): boolean => {
  return permissions.includes(AppPermissionName.WriteOthersConsultations);
};

export const canUseDiagnosisAssistant = (permissions: AppPermissionName[]): boolean =>
  permissions.includes(AppPermissionName.DiagnosisAssistant);

export const canUseSkincareAssistant = (permissions: AppPermissionName[]): boolean =>
  permissions.includes(AppPermissionName.SkincareAssistant);
